import { makeAutoObservable, toJS } from 'mobx'

import { parseDate, removeAtIndex } from '@src/lib'
import type { Branded } from '@src/lib/brand'
import { CallTranscriptModel } from '@src/service/model/CallTranscriptModel'
import MessageMediaModel from '@src/service/model/MessageMediaModel'
import type { Model } from '@src/service/model/base'
import { Comment } from '@src/service/model/comment'
import ActivityReaction from '@src/service/model/reactions/ActivityReaction'
import type { CodableTagLite } from '@src/service/model/tags/TagModel'

import type { MemberModel, RingOrderType } from '..'

import {
  convertLoadMoreActivityIdToNormalActivityId,
  createActivityId,
  createLoadMoreActivityId,
  validateActivityId,
  validateLoadMoreActivityId,
} from './utils'

export interface ActivityError {
  code?: number
  message?: string
  logLevel?: string
  secondaryMessage?: string
  logType?: string
  docs?: string
  causes?: string
  solutions?: string
  description?: string
}

export type ActivityMessageStatus =
  | 'queued'
  | 'sent'
  | 'received'
  | 'delivered'
  | 'failed'
  | 'undelivered'

export type LegacyActivityCallStatus =
  | 'ringing'
  | 'in-progress'
  | 'completed'
  | 'no-answer'
  | 'canceled'
  | 'busy'

export type ActivityStatus = LegacyActivityCallStatus | ActivityMessageStatus

export interface CallSummary {
  $version: string | null
  summary: string[]
  nextSteps: string[]
}

export type CallStatus =
  | 'ringing'
  | 'completed'
  | 'canceled'
  | 'in-progress'
  | 'missed'
  | 'forwarded'
  | 'answered'
  | 'abandoned'

export type CallStatusReason =
  | 'auto-forward'
  | 'business-hours'
  | 'muted'
  | 'dnd'
  | 'work-schedule'
  | 'declined'
  | 'all-declined'
  | 'no-answer'
  | 'play-audio'
  | 'after-hours-play-audio'
  | 'op-hung-up'
  | 'phone-menu'
  | 'phone-menu-dial'
  | 'phone-menu-voicemail'
  | 'phone-menu-audio'
  | 'phone-menu-forward'

export type CallStatusPhoneNumberType = 'shared' | 'private'

type ForwardType = 'phone-number' | 'phone-menu'

type UserCallStatus = {
  userId: string
  status: CallStatus
  statusReason?: CallStatusReason
  forwardType?: ForwardType
  forwardedVia?: string
  phoneMenuDestination?: string
  forwardDestination?: string
}

type RingLogGroupBatch = {
  userStatuses: UserCallStatus[]
  ringDuration: number
}

type RingLogGroup = {
  totalRingDuration: number
  batches: RingLogGroupBatch[]
}

type CallActivityRingLog = {
  ringOrderType: RingOrderType
  groups: RingLogGroup[]
}

type CallActivityDialSource = 'phone-menu' | 'phone-number'

export interface ActivityCallStatus {
  status: CallStatus
  phoneNumberType: CallStatusPhoneNumberType
  dialSource: CallActivityDialSource
  callActivityStatus: UserCallStatus
  ringLog: CallActivityRingLog | null
}

type ActivityMeta = {
  isAutoResponse?: boolean
  lastUpdateBy: string
  notified: string[]
  sessionId: string
}

export type ActivityPresence = {
  member: MemberModel | null
  status: 'typing' | 'viewing'
}

export type MessageDirection = 'incoming' | 'outgoing'

export type NotConnectedReason =
  | 'hungup'
  | 'dnd'
  | 'muted'
  | 'no-one-available'
  | 'after-hours'
  | 'no-ivr-selection'
  | 'blocklist'

export type MediaProcessingStatus = 'absent' | 'in-progress' | 'completed' | 'failed'

type Options = {
  /**
   * If coming from a conversation when the activity is sent
   * the conversation will be marked as done. If the conversation
   * is already in `Done` status this flag will tell the backend
   * not to move the conversation to `Open`
   */
  markAsDone: boolean
}

export type LoadMoreActivityId = Branded<string, 'LoadMoreActivityId'>
export type ActivityId = Branded<string, 'ActivityId'>

export default class ActivityModel implements IActivity, Model {
  id: ActivityId | LoadMoreActivityId = ActivityModel.createActivityId()
  answeredAt: number | null = null
  answeredBy: string | null = null
  body: string | null = null
  clientId: string | null = null
  completedAt: number | null = null
  conversationId: string | null = null
  createdAt: number | null = Date.now()
  createdBy: string | null = null
  presence: ActivityPresence[] = []
  direction: MessageDirection | null = null
  duration: number | null = null
  enrichment: Enrichment | null = null
  error: ActivityError | null = null
  from: string | null = null
  initiatedAt: number | null = null
  media: MessageMediaModel[] = []
  notConnectedReason: NotConnectedReason | null = null
  recordingUrl: string | null = null
  roomId: string | null = null
  sentAt: number | null = null
  status: ActivityStatus | null = null
  to: string | null = null
  transcript: string | null = null
  type: 'message' | 'call' | 'voicemail' | 'loading' | 'typing' | null = null
  updatedAt: number | null = Date.now()
  resolvedAt: number | null = null
  resolvedBy: string | null = null
  sid: string | null = null
  meta?: ActivityMeta
  callTranscript: CallTranscriptModel | null = null
  callSummary: CallSummary | null = null
  callStatus: ActivityCallStatus | null = null
  recordingStatus: MediaProcessingStatus = 'absent'
  transcriptionStatus: MediaProcessingStatus = 'absent'
  summarizationStatus: MediaProcessingStatus = 'absent'

  // Pagination params
  beforeId?: string | null = null
  afterId?: string | null = null

  // Relations
  reactions: ActivityReaction[] = []
  comments: Comment[] = []

  // Tags (AI/Manual)
  tags: CodableTagLite[] = []

  // Options
  options?: Options

  constructor(attrs?: Partial<ActivityModel>, options?: Options) {
    this.deserialize(attrs)
    makeAutoObservable(this, {})
    this.options = options
  }

  get isPaginationPlaceholder() {
    return this.afterId || this.beforeId
  }

  get isOutgoing(): boolean {
    return this.direction === 'outgoing'
  }

  get isAutoResponse(): boolean {
    return !!this.meta?.isAutoResponse
  }

  get isMissedCall() {
    if (this.type !== 'call') {
      return false
    }
    return (
      this.status === 'busy' ||
      this.status === 'no-answer' ||
      (this.status === 'completed' && !this.answeredAt)
    )
  }

  get participants() {
    // TODO: We're waiting on backend to implement this feature.
    return []
  }

  get isGroupCall(): boolean {
    return this.participants.length > 2
  }

  get recordings() {
    return !this.media && this.recordingUrl
      ? [new MessageMediaModel({ url: this.recordingUrl, type: 'audio/mpeg' })]
      : this.media
  }

  deleteReactionLocally(reaction: ActivityReaction) {
    const reactionIndex = this.reactions.findIndex((r) => r === reaction)
    this.reactions = removeAtIndex(this.reactions, reactionIndex)
  }

  deserialize = (attrs: any) => {
    if (attrs) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- FIXME: Fix this ESLint violation!
      const {
        media,
        comments,
        reactions,
        meta,
        callTranscript,
        callStatus,
        labels,
        ...json
      } = attrs

      Object.assign(this, json)

      if (callTranscript) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument -- FIXME: Fix this ESLint violation!
        this.callTranscript = new CallTranscriptModel(callTranscript)
      }
      // If we receive an nullish `callStatus` or `callActivityStatus` from the backend (which can happen on certain ws messages, for example `alert-update`),
      // we preserve the existing call status, otherwise we default it to `null`.
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access -- FIXME: Fix this ESLint violation!
      this.callStatus = callStatus?.callActivityStatus
        ? callStatus
        : this.callStatus ?? null
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-argument -- FIXME: Fix this ESLint violation!
      this.media = media?.map((m) => new MessageMediaModel().deserialize(m)) ?? []
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- FIXME: Fix this ESLint violation!
      this.reactions =
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-argument -- FIXME: Fix this ESLint violation!
        reactions?.map?.((r) => new ActivityReaction(this, r)) ?? []
      if (labels) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-argument -- FIXME: Fix this ESLint violation!
        this.tags = labels as CodableTagLite[]
      }
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-argument -- FIXME: Fix this ESLint violation!
      this.comments = comments?.map?.((c) => new Comment(this, c)) ?? []
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access -- FIXME: Fix this ESLint violation!
      this.answeredAt = parseDate(json.answeredAt || this.answeredAt)
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access -- FIXME: Fix this ESLint violation!
      this.completedAt = parseDate(json.completedAt || this.completedAt)
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access -- FIXME: Fix this ESLint violation!
      this.createdAt = parseDate(json.createdAt || this.createdAt)
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access -- FIXME: Fix this ESLint violation!
      this.initiatedAt = parseDate(json.initiatedAt || this.initiatedAt)
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access -- FIXME: Fix this ESLint violation!
      this.sentAt = parseDate(json.sentAt || this.sentAt)
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access -- FIXME: Fix this ESLint violation!
      this.updatedAt = parseDate(json.updatedAt || this.updatedAt)
      if (meta) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access -- FIXME: Fix this ESLint violation!
        this.resolvedAt = parseDate(meta.resolvedAt)
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access -- FIXME: Fix this ESLint violation!
        this.resolvedBy = meta.resolvedBy
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access -- FIXME: Fix this ESLint violation!
        this.roomId = meta.roomId
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access -- FIXME: Fix this ESLint violation!
        this.notConnectedReason = meta.notConnectedReason ?? null
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- FIXME: Fix this ESLint violation!
        this.meta = meta
      }
    }
    return this
  }

  serialize = () => {
    return {
      id: this.id,
      sid: this.sid,
      answeredAt: this.answeredAt,
      answeredBy: this.answeredBy,
      body: this.body,
      clientId: this.clientId,
      completedAt: this.completedAt,
      conversationId: this.conversationId,
      createdAt: this.createdAt,
      createdBy: this.createdBy,
      direction: this.direction,
      duration: this.duration,
      enrichment: toJS(this.enrichment),
      error: toJS(this.error),
      from: this.from,
      initiatedAt: this.initiatedAt,
      media: this.media.map((m) => m.serialize()),
      meta: toJS(this.meta),
      presence: toJS(this.presence),
      reactions: this.reactions.map((r) => r.serialize()),
      comments: this.comments.map((c) => c.serialize()),
      recordingUrl: this.recordingUrl,
      sentAt: this.sentAt,
      status: this.status,
      to: this.to,
      transcript: this.transcript,
      type: this.type,
      updatedAt: this.updatedAt,
      resolvedAt: this.resolvedAt,
      resolvedBy: this.resolvedBy,
      notConnectedReason: this.notConnectedReason,
      beforeId: this.beforeId,
      afterId: this.afterId,
      recordingStatus: this.recordingStatus,
      transcriptionStatus: this.transcriptionStatus,
      callTranscript: this.callTranscript?.serialize() ?? null,
      summarizationStatus: this.summarizationStatus,
      callSummary: toJS(this.callSummary),
      callStatus: toJS(this.callStatus),
      tags: toJS(this.tags),
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function -- FIXME: Fix this ESLint violation!
  tearDown = () => {}

  static validateActivityId = validateActivityId
  static validateLoadMoreActivityId = validateLoadMoreActivityId

  static createActivityId = createActivityId
  static createLoadMoreActivityId = createLoadMoreActivityId
  static convertLoadMoreActivityIdToNormalActivityId =
    convertLoadMoreActivityIdToNormalActivityId
}

export interface Enrichment {
  taggedIds: {
    groupIds: string[]
    orgIds: string[]
    userIds: string[]
  }
  tokens: {
    [key: string]: {
      locations: { startIndex: number; endIndex: number }[]
      replacement: string
      token: string
      type: 'mention'
    }
  }
}

export interface IActivity {
  id: ActivityModel['id']
  answeredAt: number | null
  answeredBy: string | null
  body: string | null
  clientId: string | null
  completedAt: number | null
  conversationId: string | null
  // FIXME: WEB-153 - createdAt should be a string
  createdAt: number | null
  createdBy: string | null
  direction: MessageDirection | null
  duration: number | null
  enrichment: Enrichment | null
  error: ActivityError | null
  from: string | null
  initiatedAt: number | null
  media: MessageMediaModel[]
  recordingUrl: string | null
  sentAt: number | null
  status: ActivityStatus | null
  to: string | null
  transcript: string | null
  type: 'message' | 'call' | 'voicemail' | 'loading' | 'typing' | null
  // FIXME: WEB-153 - updatedAt should be a string
  updatedAt: number | null
  meta?: ActivityMeta
  callSummary: CallSummary | null
  callTranscript: CallTranscriptModel | null
  callStatus: ActivityCallStatus | null
  recordingStatus: MediaProcessingStatus
  transcriptionStatus: MediaProcessingStatus
  summarizationStatus: MediaProcessingStatus
  tags: CodableTagLite[]
}
