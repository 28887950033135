import { makeAutoObservable } from 'mobx'

import PersistedCollection from '@src/service/collections/PersistedCollection'
import { TagModel } from '@src/service/model/tags'
import type { CodableTag } from '@src/service/model/tags/TagModel'
import type { TagRepository } from '@src/service/worker/repository/TagRepository'
import { TAG_TABLE_NAME } from '@src/service/worker/repository/TagRepository'

import type Service from '..'

export default class TagStore {
  readonly collection: PersistedCollection<TagModel, TagRepository>

  constructor(private root: Service) {
    this.collection = new PersistedCollection({
      table: root.storage.table(TAG_TABLE_NAME),
      classConstructor: (json: CodableTag) => new TagModel(json),
    })

    makeAutoObservable(
      this,
      {},
      {
        autoBind: true,
      },
    )
  }

  load() {
    return this.collection.performQuery((repo) => repo.all())
  }

  async getByPhoneNumberId(phoneNumberId: string) {
    const tags =
      await this.root.transport.communication.tags.listByPhoneNumberId(phoneNumberId)
    const tagModels = await this.collection.load(tags)
    return tagModels
  }

  // TODO: finish up implementation when the backend is ready
  // https://linear.app/openphone/issue/PRODU-29/update-action-menu-items-for-labels-connect-with-be
  async delete({ phoneNumberId, tagId }: { phoneNumberId: string; tagId: string }) {
    const tag = this.collection.get(tagId)

    if (!tag) {
      return
    }

    this.collection.delete(tagId)
    try {
      await this.root.transport.communication.tags.delete({ phoneNumberId, tagId })
    } catch (error) {
      this.collection.put(tag)
      throw error
    }
  }
}
