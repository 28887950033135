import { makeAutoObservable, observable } from 'mobx'

import { DisposeBag } from '@src/lib/dispose'

import type Service from '.'
import PersistedCollection from './collections/PersistedCollection'
import type { RawWorkflowDefinition } from './model/workflow/WorkflowDefinitionModel'
import WorkflowDefinitionModel from './model/workflow/WorkflowDefinitionModel'
import type { RawWorkflowStepDefinition } from './model/workflow/WorkflowStepDefinitionModel'
import WorkflowStepDefinitionModel from './model/workflow/WorkflowStepDefinitionModel'
import type { RawWorkflowTriggerDefinition } from './model/workflow/WorkflowTriggerDefinitionModel'
import WorkflowTriggerDefinitionModel from './model/workflow/WorkflowTriggerDefinitionModel'
import {
  WORKFLOW_DEFINITION_TABLE_NAME,
  type WorkflowDefinitionRepository,
} from './worker/repository/WorkflowDefinitionRepository'
import type { WorkflowStepDefinitionRepository } from './worker/repository/WorkflowStepDefinitionRepository'
import { WORKFLOW_STEP_DEFINITION_TABLE_NAME } from './worker/repository/WorkflowStepDefinitionRepository'
import type { WorkflowTriggerDefinitionRepository } from './worker/repository/WorkflowTriggerDefinitionRepository'
import { WORKFLOW_TRIGGER_DEFINITION_TABLE_NAME } from './worker/repository/WorkflowTriggerDefinitionRepository'

export default class WorkflowStore {
  readonly definitionsCollection: PersistedCollection<
    WorkflowDefinitionModel,
    WorkflowDefinitionRepository
  >
  readonly stepDefinitionsCollection: PersistedCollection<
    WorkflowStepDefinitionModel,
    WorkflowStepDefinitionRepository
  >
  readonly triggerDefinitionsCollection: PersistedCollection<
    WorkflowTriggerDefinitionModel,
    WorkflowTriggerDefinitionRepository
  >

  private readonly disposeBag = new DisposeBag()

  constructor(private root: Service) {
    this.definitionsCollection = new PersistedCollection({
      table: root.storage.table(WORKFLOW_DEFINITION_TABLE_NAME),
      classConstructor: (json: RawWorkflowDefinition) =>
        new WorkflowDefinitionModel(json),
    })
    this.stepDefinitionsCollection = new PersistedCollection({
      table: root.storage.table(WORKFLOW_STEP_DEFINITION_TABLE_NAME),
      classConstructor: (json: RawWorkflowStepDefinition) =>
        new WorkflowStepDefinitionModel(json),
    })
    this.triggerDefinitionsCollection = new PersistedCollection({
      table: root.storage.table(WORKFLOW_TRIGGER_DEFINITION_TABLE_NAME),
      classConstructor: (json: RawWorkflowTriggerDefinition) =>
        new WorkflowTriggerDefinitionModel(json),
    })

    makeAutoObservable<this>(this, {
      definitionsCollection: observable,
      stepDefinitionsCollection: observable,
      triggerDefinitionsCollection: observable,
    })

    this.disposeBag.add(this.subscribeToWebSocket())
    this.load()
  }

  temp() {
    // TODO placeholder
    this.root.transport.workflow.definitions.list(10).then((response) => {
      console.log(response)
    })
    this.root.transport.workflow.stepDefinitions.list(10).then((response) => {
      console.log(response)
    })
    this.root.transport.workflow.triggerDefinitions.list(10).then((response) => {
      console.log(response)
    })
  }

  private load() {
    return Promise.all([
      this.definitionsCollection.performQuery((repo) => repo.all()),
      this.stepDefinitionsCollection.performQuery((repo) => repo.all()),
      this.triggerDefinitionsCollection.performQuery((repo) => repo.all()),
    ])
  }

  private subscribeToWebSocket() {
    return this.root.transport.onNotificationData.subscribe((data) => {
      switch (data.type) {
        case 'ring-order-update': {
          // TODO placeholder
          break
        }
      }
    })
  }
}
