import type { DBTypes } from '@openphone/dexie-database/types'
import { makeAutoObservable, toJS } from 'mobx'
import type { MarkOptional } from 'ts-essentials'

import uuid from '@src/lib/uuid'
import type { Model } from '@src/service/model/base'

export type RawWorkflowDefinition = DBTypes.WorkflowDefinitionRow

export default class WorkflowDefinitionModel implements Model {
  private raw: RawWorkflowDefinition

  get id(): string {
    return this.raw.id
  }

  constructor(attrs: MarkOptional<RawWorkflowDefinition, 'id'>) {
    this.raw = {
      ...attrs,
      id: attrs.id ?? `WD${uuid()}`.replace(/-/g, ''),
    }

    makeAutoObservable<this>(this)
  }

  deserialize(json: RawWorkflowDefinition): this {
    this.raw = json
    return this
  }

  serialize(): RawWorkflowDefinition {
    return toJS(this.raw)
  }

  localUpdate(attrs: Partial<RawWorkflowDefinition>): this {
    this.raw = { ...this.raw, ...attrs }
    return this
  }
}
